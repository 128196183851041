import React, { useState, useEffect } from "react";
import SearchResult from "../../components/search-result/SearchResult";
import Select from "../../components/select/Select";
import Title from "../../components/title/Title";
import styles from './search.module.scss'
import searchHeader from '../../assets/json/inputs/search.json'
import params from '../../assets/json/inputs/search-parameters.json'
import * as utils from "../../utils/utils";
import { Spin } from 'antd';
import debouncingMethod from '../../components/header/Search'

function Search(props) {

    const [searchParams, setParams] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')
    const [customers, setCustomers] = useState([])
    const [filteredCustomers, setFilteredCustomers] = useState([])
    const all = 'All'
    const [region, setRegion] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')

    useEffect(() => {
        utils.getData('search/getparameters', 'GET')
            .then(resp => {
                const _searchParams = []
                params.forEach(element => {
                    _searchParams.push({
                        label: element.label,
                        values: [all, ...resp[element.value]]
                    })
                })
                setLoading(false)
                setParams(_searchParams)
            })
    }, [])

    const [ascending, setAscending] = useState(true)

    const [columns, setColumns] = useState(JSON.parse(JSON.stringify(searchHeader)));

    const onChange = (event) => {
        setSearch(event.target.value)
        if (!event.target.value.length) {
            setCustomers([])
            setFilteredCustomers([])
        }
        debouncingMethod(event.target.value.toLowerCase()).then(resp => {
            let customers = []
            if (resp.group) {
                resp.group.forEach(element => {
                    element['group_productdivision'] = 'Life Sciences';
                    element['cxValue'] = element.cxScore.score;
                    element['casValue'] = element.cas.score;
                    customers.push(element);
                });
            }
            if (resp.productDivisions) {
                resp.productDivisions.forEach(_element => {
                    _element.customers.forEach(element => {
                        element['group_productdivision'] = _element.code;
                        element['cxValue'] = element.cxScore.score;
                        element['casValue'] = element.cas.score;
                        element['productDivision'] = {
                            name: _element.code,
                            code: _element.code,
                            cas: _element.cas,
                            cxScore: _element.cxScore,
                            lastQuarterCas: _element.lastQuarterCas,
                            lastQuarterCxScore: _element.lastQuarterCxScore,
                            primaryRecommendation: _element.primaryRecommendation,
                            secondaryRecommendations:_element.secondaryRecommendations ? _element.secondaryRecommendations :[],
                            volatility: _element.volatility
                        }
                        customers.push(element);
                    });
                });
            }
            customers = customers.sort(utils.sorting("name", "string", ascending))
            setCustomers(customers)
            setFilteredCustomers(customers)
        })
    }

    const back = () => props.history.push('/dashboard')

    const handleChange = (event, type) => {
        if (type.label === 'Region') {
            if (event.target.value !== all) {
                setRegion(event.target.value)
            } else {
                setRegion('')
            }
        } else if (type.label === 'Country') {
            if (event.target.value !== all) {
                setCountry(event.target.value)
            } else {
                setCountry('')
            }
        } else if (type.label === 'State') {
            if (event.target.value !== all) {
                setState(event.target.value)
            } else {
                setState('')
            }
        } else if (type.label === 'Location (City)') {
            if (event.target.value !== all) {
                setCity(event.target.value)
            } else {
                setCity('')
            }
        }
    }

    const applyFilter = () => {

        let _customers = search.length ? customers : []

        if (region) {
            _customers = customers.filter(element => element.region && element.region.toLowerCase() ===
                region.toLowerCase())
        }
        if (country) {
            _customers = _customers.filter(element => element.country && element.country.toLowerCase() ===
                country.toLowerCase())
        }
        if (state) {
            _customers = _customers.filter(element => element.state && element.state.toLowerCase() ===
                state.toLowerCase())
        }
        if (city) {
            _customers = _customers.filter(element => element.city && element.city.toLowerCase() ===
                city.toLowerCase())
        }

        setFilteredCustomers(_customers)
    }

    useEffect(() => {
        applyFilter()
    }, [region, country, state, city])

    useEffect(() => {
        applyFilter()
    }, [customers])

    const listSorting = (item) => {
        if (item.sortable) {
            const dataArray = [...filteredCustomers];
            const type = (item.selector === 'cxValue' || item.selector === 'casValue') ? 'number' : 'string'
            let list = dataArray.sort(
                utils.sorting(item.selector, type, !ascending)
            );
            setFilteredCustomers(list);
            setAscending(!ascending)

            let columnsArray = [...columns];
            columnsArray.forEach((element) => {
                if (element.name === item.name) {
                    element.isActive = true;
                } else if (element.isActive !== undefined) {
                    element.isActive = false;
                }
            });
            setColumns(columnsArray);
        }
    };

    const setCustomer = (customer) => {
        const { cas, cxScore, productDivision } = customer
        let obj = {
            cas: cas.score,
            casBenchmark: cas.benchmark,
            code: customer.code,
            cxBenchmark: cxScore.benchmark,
            cxScore: cxScore.score,
            lastQuarterCas: cas.lastQuarterValue,
            lastQuarterCxScore: cxScore.lastQuarterValue,
            name: customer.name,
            primaryRecommendation: customer.primaryRecommendation,
            secondaryRecommendations:customer.secondaryRecommendations ? customer.secondaryRecommendations:[],
            responseCount: cas.responseCount,
            volatility: customer.volatility,
        }
        if (productDivision) {
            props.setProductDivision(productDivision)
        } else {
            props.setProductDivision({})
        }
        props.setCustomer(obj)
        props.history.push("/dashboard/customerdetail")
    }

    return (
        <Spin spinning={loading}>
            <div className={styles.container}>
                <div className={styles.headerSection}>
                    {/* <div onClick={back} className={styles.backBtn}>Back</div> */}
                    <div className={styles.backBtn}>Back</div>
                    <Title value="Advanced Search" />
                </div>
                <div className={styles.searchArea}>
                    <div className={styles.firstDiv}></div>
                    <div className={styles.secondDiv}>
                        <input
                            type='text'
                            className={styles.search}
                            value={search}
                            onChange={onChange}
                            placeholder="search here..." />
                    </div>
                    <div className={styles.firstDiv}></div>
                </div>
                <div className={styles.searchParams} >
                    {
                        searchParams.map((element, index) => (
                            <div key={index} className={styles.paramsItems}>
                                <div>{element.label}</div>
                                <div>
                                    <Select
                                        handleChange={handleChange}
                                        values={element.values}
                                        type={element} />
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div>
                    <SearchResult
                        columns={columns}
                        searchResult={filteredCustomers}
                        listSorting={listSorting}
                        ascending={ascending}
                        setCustomer={setCustomer} />
                </div>
            </div>
        </Spin>
    )
}

export default Search