import React, { useState, useEffect, useRef  } from 'react'
import SearchItem from './search-item/SearhItem'
import styles from './search.module.scss'
import { Spin } from 'antd';

function Search(props) {
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- for Breadcrumbs
    const { search, customers, isLoading } = props
    const box = useRef(null);
    let regon = JSON.parse(sessionStorage.getItem("selectedRegion"));

    //Filtered 
    let selectedRegion = props.selectedRegion;
    if(!selectedRegion){
        selectedRegion="NA";
    }
    let filteredCustomers = customers?.filter((cust) => cust.company_region?.toLowerCase()===regon.toLowerCase());

    useOutsideAlerter(box);
    const advancedSearch = () => {
        props.cancel()
        props.history.push("/dashboard/search");
    }

    const setCustomer = (customer) => {
        const { cas, cxScore, productDivision } = customer
        let obj = {
            cas: customer.cas,
            casBenchmark: customer.casCustomerBenchmark,
            code: customer.code,
            cxBenchmark: customer.cxCustomerBenchmark,
            cxScore: customer.cxScore,
            company_region: customer.company_region,
            //EMEA Release- -- need to add company_region and other details here
            lastQuarterCas: customer.lastQuarterValue,
            lastQuarterCxScore: customer.lastQuarterCxScore,
            name: customer.name,
            primaryRecommendation: customer.primaryRecommendation,
            secondaryRecommendations:customer.secondaryRecommendations ? customer.secondaryRecommendations :[],
            responseCount: customer.responseCount,
            volatility: customer.volatility,
            whiteGlove:customer.whiteGlove,
            id:customer.id
        }
        // if (productDivision) {
        //     props.setProductDivision(productDivision)
        // } else {
        //     props.setProductDivision({})
        // }
        props.setSubRegion({});
        props.setCustomer(obj)
        props.cancel()
        props.history.push("/dashboard/customerdetail")
    }
    const [toggle, setToggle] = useState(false);
    const triggerToggle = () => {
        setToggle(!toggle)
    }
    function useOutsideAlerter(ref) {
        useEffect(() => {

            // Function for click event
            function handleOutsideClick(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.cancel()
                }
            }

            // Adding click event listener
            document.addEventListener("click", handleOutsideClick);

        }, [ref]);
    }

    return (
        <React.Fragment>
            {
                search.length >= 3 ?
                    <div className={styles.container} ref={box}>
                        <div className={styles.header}>
                            <div className={styles.cross} onClick={props.cancel}></div>
                            {/* <div>
                                        <span>NSGN</span>
                                    </div>
                                    <div className={styles.toggleButton} onClick={triggerToggle}>
                                            <div className={toggle?styles.checked:styles.toggleCircle}></div>
                                        </div>
                                    <div className={styles.pRight30}>
                                        <span>SGN</span>
                                    </div> */}
                        </div>
                        <Spin spinning={isLoading} >
                        <div className={styles.result}>
                            {/*Added this Search filter - region specific customers for EMEA release*/
                                filteredCustomers && filteredCustomers.length ?
                                filteredCustomers.map((customer, index) => (
                                        <SearchItem setCustomer={setCustomer} key={index} customer={customer} />
                                    )) : <div className={styles.nodata}>
                                       {(!isLoading)?'No data found!':''} 
                                        </div>
                            }
                        </div>
                        </Spin>
                        {/* <div className={styles.footer}>
                                    <span onClick={advancedSearch} >Advanced Search</span>
                                </div> */}
                    </div> : null
            }


        </React.Fragment>

    )

}

export default Search