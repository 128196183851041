import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./detailedview.module.scss";
import CXScore from "./Tiles/CXScore";
import CAS from "./Tiles/CAS";
import * as utils from "../../utils/utils";
import { Spin } from 'antd';
import CXList from './Tiles/CXList';
import * as consts from '../../utils/constant';
import * as favUtils from '../../utils/favorite.utils';
import Attributes from '../attributes/customer'
import * as constants from "../../../src/utils/constant";
import * as nbaurls from "../../../src/utils/nba.utils"

const Customer = (props) => {
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    const { customer } = props;
    
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- 
    const primaryRecommendation = customer.primaryRecommendation ? customer.primaryRecommendation.toLocaleLowerCase() : ''
    const secondaryRecommendations = customer.secondaryRecommendations ? customer.secondaryRecommendations : [];
    
    const [customerDetails, setCustomerDetails] = useState(null)
    
    const breadcrumbList = [
        {
            value: `Dashboard (${region})`,
            path: "/dashboard",
        }
    ];

    if (!utils.isEmpty(props.selectedProductDivision)) {
        breadcrumbList.push({ value: `${props.selectedProductDivision.code} (${region})`, path: '/dashboard/productdivision' })
    }
    breadcrumbList.push({ value: `${utils.capitalize(props.customer.name)} (${region})`, path: '/' })
    const formatData = (result) => {
        const result1 = (result && result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        return (result1 && result1['_source']) ? result1['_source'] : {};
    }

    useEffect(() => {
        setLoading(true)
        const type = utils.isEmpty(props.selectedProductDivision) ? 'customer': 'productDivisionCustomer'
        //EMEA Release
      
        let params = {
            "size": 1,
                "query": {
                  "bool": {
                    "must": [
                      {
                        "match": {
                          "company_region": region
                        }
                      },
                      {
                        "match": {
                          "code": customer.code
                        }
                      }
                    ]
                  }
              }
        }
        if (type === 'productDivisionCustomer') {
            params = {
                "size": 1,
                "query": {
                    "bool": {
                        "should": [
                            {
                                "bool": {
                                    "must": [
                                        {
                                            /*"bool": {
                                                "should": [
                                                    {
                                                        "match": {
                                                            "code": customer.code
                                                        }
                                                    },
                                                    {
                                                        "match": {
                                                            "volatility": customer.volatility
                                                        }
                                                    }
                                                ]
                                            }*/
                                            "match": {
                                                "code": customer.code
                                            }
                                            
                                        },
                                        {
                                            "match": {
                                                "product_division_code": props.selectedProductDivision.code
                                            }
                                        },
                                        {
                                            "match": {
                                              "company_region": region
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
            }
        }

           //EMEA Release-
        let groupCustConstant, pdCustConstant;
        if(type === 'customer'){
            if(region.toLowerCase()==="emea")
            groupCustConstant = constants.Customer_EMEA_ScoreDetails_Index
            else
            groupCustConstant = constants.Customer_ScoreDetails_Index
        }
        else{
            if(region.toLowerCase()==="emea")
            pdCustConstant = constants.PD_EMEA_customer_ScoreDetails_Index
           else
            pdCustConstant = constants.PD_customer_ScoreDeatils_Index
        }

        const input = {
            //name: (type === 'customer') ? constants.Customer_ScoreDetails_Index : constants.PD_customer_ScoreDeatils_Index,
            name: (type === 'customer') ? groupCustConstant : pdCustConstant,
            queryParams: params
        }
        setLoading(true)
        
       utils.getDataES('-elasticsearch', 'POST', input)
            .then(resp => {
                
                let ESData = formatData(resp.data);
                
                if(Object.keys(ESData).length>0){
                    const {company_region} = ESData
                    setRegion(company_region)
                }
                else{
                    setRegion(JSON.parse(sessionStorage.getItem("selectedRegion")))
                }
                setCustomerDetails(ESData)
                //setData(JSON.parse(JSON.stringify(result)))
                setLoading(false)
            }).catch(err => setLoading(false))
    }, [props.customer])

    let cxScore, cas, cxList, cxListDP;
    const getSortedCXList = (cxList) => {
        
        if(cxList){
            let list = [...cxList]
            list.forEach(element => {
                element.difference = element.score - element.lastQuarterValue
                element.differenceCent = ((element.score - element.lastQuarterValue) / (element.lastQuarterValue)) * 100
            });
        
            return list.sort(utils.sorting('differenceCent', 'number', false))
        }
        return cxList
        
        
    }

    if (customerDetails && Object.keys(customerDetails).length>0) {
        
        cxScore = customerDetails.cxScore;
        if (customerDetails && customerDetails.cxList && customerDetails.cxList.length > 0) {
            cxListDP = customerDetails.cxList.find(item => item.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase());
            cxScore.dataPoints = (cxListDP && cxListDP.dataPoints) ? cxListDP.dataPoints : ''
        }
        cas = customerDetails.cas;
        cxList = getSortedCXList(customerDetails.cxList);
    }
    else{
        //customerDetails={};
        cxScore={};
        cas= {};
        cxList = [{score:0}];
    }

    const createBreadCrumb = () => {
        breadcrumbList[breadcrumbList.length - 1].path = '/dashboard/customerdetail'
        return breadcrumbList;
    }

    const viewScore = (score) => {
        if(customerDetails && Object.keys(customerDetails).length>0)
        {
            props.setCXScoreData(customerDetails);
            props.setBreadCrumb(createBreadCrumb());
            if (score === 'cas') {
                props.setSASParent('cas');
                props.history.push("/dashboard/score/cas");
            } else {
                props.setSASParent('cx');
                props.history.push("/dashboard/score");
            }
        }
    };

    const getInsights = (nba) => {
        if (customerDetails.nba_insights) {
            let insights = customerDetails.nba_insights.filter(element => element.metrics_name.toLowerCase() === nba.toLowerCase())
            if (insights && insights.length) {
                return insights[0].insights
            }
            return []
        }
        return []
    }

    const primaryView = (nba) => {
        let _nba;
        const { code } = props.customer
        let urls = nbaurls.customer_nba_urls(nba, code, region)
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    let pd_cust_urls = nbaurls.pd_customer_nba_urls(nba, code, divisionCode)
                    _nba = {
                        name: nba,
                        primaryView: pd_cust_urls.primaryView,
                        childView: pd_cust_urls.childView,
                        historyView: pd_cust_urls.historyView,
                        insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                    }
                    props.setNBA(_nba)
                } else {
                    _nba = {
                        name: nba,
                        primaryView: urls.primaryView,
                        childView: urls.childView,
                        historyView: urls.historyView,
                        insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                    }
                    props.setNBA(_nba)
                }
                props.setBannerType(consts.IMPROVE_INVOICE_AGING.replace(/\s+/g, ''));
                break;
            case consts.REDUCE_RETURN_PERCENTAGE:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    let pd_cust_urls = nbaurls.pd_customer_nba_urls(nba, code, divisionCode)
                    _nba = {
                        name: nba,
                        primaryView: pd_cust_urls.primaryView,
                        childView: pd_cust_urls.childView,
                        historyView: pd_cust_urls.historyView,
                        insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                    }
                    props.setNBA(_nba)
                } else {
                    _nba = {
                        name: nba,
                        primaryView: urls.primaryView,
                        childView: urls.childView,
                        historyView: urls.historyView,
                        insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                    }
                    props.setNBA(_nba)
                }
                props.setBannerType(consts.REDUCE_RETURN_PERCENTAGE.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    let pd_cust_urls = nbaurls.pd_customer_nba_urls(nba, code, divisionCode)
                    props.setNBA({
                        name: nba,
                        primaryView: pd_cust_urls.primaryView,
                        childView: pd_cust_urls.childView,
                        historyView: pd_cust_urls.historyView,
                        insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                    })
                } else {
                    props.setNBA({
                        name: nba,
                        primaryView: urls.primaryView,
                        childView: urls.childView,
                        historyView: urls.historyView,
                        insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                    })
                }
                props.setBannerType(consts.IMPROVE_PRODUCT_AVAILABILITY.replace(/\s+/g, ''));
                break;
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    let pd_cust_urls = nbaurls.pd_customer_nba_urls(nba, code, divisionCode)
                    const _nba = {
                        name: nba,
                        primaryView: pd_cust_urls.primaryView,
                        childView: pd_cust_urls.childView,
                        historyView: pd_cust_urls.historyView,
                        insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                    }
                    props.setNBA(_nba)
                } else {
                    const _nba = {
                        name: nba,
                        primaryView: urls.primaryView,
                        childView: urls.childView,
                        historyView: urls.historyView,
                        insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                    }
                    props.setNBA(_nba)
                }
                props.setBannerType(consts.NEW_IMPROVE_ORDER_VELOCITY.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_OTIF:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    let pd_cust_urls = nbaurls.pd_customer_nba_urls(nba, code, divisionCode)
                    _nba = {
                        name: nba,
                        primaryView: pd_cust_urls.primaryView,
                        childView: pd_cust_urls.childView,
                        historyView: pd_cust_urls.historyView,
                        insights: getInsights(consts.IMPROVE_OTIF)
                    }
                    props.setNBA(_nba)
                } else {
                    _nba = {
                        name: nba,
                        primaryView: urls.primaryView,
                        childView: urls.childView,
                        historyView: urls.historyView,
                        insights: getInsights(consts.IMPROVE_OTIF)
                    }
                    props.setNBA(_nba)
                }
                props.setBannerType(consts.IMPROVE_OTIF.replace(/\s+/g, ''));
                break;
            case consts.INCREASE_CREDITLINE:
                if (!utils.isEmpty(props.selectedProductDivision)) {
                    const { code: divisionCode } = props.selectedProductDivision
                    let pd_cust_urls = nbaurls.pd_customer_nba_urls(nba, code, divisionCode)
                    _nba = {
                        name: nba,
                        primaryView: pd_cust_urls.primaryView,
                        childView: pd_cust_urls.childView,
                        historyView: pd_cust_urls.historyView,
                        insights: getInsights(consts.INCREASE_CREDITLINE)
                    }
                    props.setNBA(_nba)
                } else {
                    _nba = {
                        name: nba,
                        primaryView: urls.primaryView,
                        childView: urls.childView,
                        historyView: urls.historyView,
                        insights: getInsights(consts.INCREASE_CREDITLINE)
                    }
                    props.setNBA(_nba)
                }
                props.setBannerType(consts.INCREASE_CREDITLINE.replace(/\s+/g, ''));
                break;
                case consts.RECURRING_ORDER:
                    if (utils.isEmpty(props.selectedProductDivision)) {
                        _nba = {
                            name: nba,
                            primaryView: urls.primaryView,
                            childView: urls.childView,
                            historyView: urls.historyView,
                            insights: getInsights(consts.RECURRING_ORDER)
                        }
                        props.setNBA(_nba)
                    }
                    props.setBannerType(consts.RECURRING_ORDER.replace(/\s+/g, ''));
                   break;
                default:
                break;
        }

        props.setNBABreadCrumb(createBreadCrumb())
        props.history.push('/dashboard/nba/primaryview')
    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 1;
                favInput.region = region; //Added for EMEA release
                favInput.InputParameters.productDivision = props.selectedProductDivision;
                if(customer){
                    favInput.InputParameters.customer = customer;
                    favInput.InputParameters.group.company_region = favInput.InputParameters.customer.company_region; //added for EMEA release
                }
               
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    useEffect(() => {
        setFavoriteId(favUtils.chkIsFavorite(
            props.favorites,
            region, //added for EMEA release
            1,
            props.selectedProductDivision,
            customer))
    }, [customer])

    const nbas = [
        {
            nba: consts.REDUCE_RETURN_PERCENTAGE,
            primaryRecommendation: primaryRecommendation === consts.REDUCE_RETURN_PERCENTAGE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.REDUCE_RETURN_PERCENTAGE)
        },
        {
            nba: consts.IMPROVE_PRODUCT_AVAILABILITY,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_PRODUCT_AVAILABILITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_PRODUCT_AVAILABILITY)
        },
        {
            nba: consts.NEW_IMPROVE_ORDER_VELOCITY,
            primaryRecommendation: primaryRecommendation === consts.NEW_IMPROVE_ORDER_VELOCITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.NEW_IMPROVE_ORDER_VELOCITY)
        },
        {
            nba: consts.IMPROVE_OTIF,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_OTIF.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_OTIF)
        },
        {
            nba: consts.IMPROVE_INVOICE_AGING,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_INVOICE_AGING.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_INVOICE_AGING)
        },
        {
            nba: consts.INCREASE_CREDITLINE,
            primaryRecommendation: primaryRecommendation === consts.INCREASE_CREDITLINE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.INCREASE_CREDITLINE)
        }
    ]

    //condition check
    if(utils.isEmpty(props.selectedProductDivision)){
            let item= {
                nba: consts.RECURRING_ORDER,
                primaryRecommendation: primaryRecommendation === consts.RECURRING_ORDER.toLowerCase() ? true : false,
                isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.RECURRING_ORDER)
            }
            nbas.push(item)
    }

    return (
        <Spin spinning={loading}>
            <div className={styles.cxContainer}>
                <BreadCrumb
                    list={breadcrumbList}
                    {...props}
                    addToFavorite={addToFavorite}
                    isFavorite={FavoriteId ? true : false} />
                <div className="row no-gutters">
                    <div className={`${styles.leftContent} ${"col-sm-12 col-md-12 col-lg-8"}`} >
                        {
                            customerDetails ? <React.Fragment>
                                <div className={styles.scoreGrid}>
                                    {cxScore && <CXScore cxScore={cxScore} {...props} viewScore={viewScore} />}
                                    {cas && <CAS cas={cas} {...props} viewScore={viewScore} />}
                                    {cxList && <CXList cxList={cxList} {...props} />}
                                </div>
                                <div className={styles.layoutBx}>
                                    <h2 className={styles.cxHeading}>Next Best Actions</h2>
                                    <div className={styles.nbaContainer}>
                                        {
                                            nbas.map((element, index) => (
                                                <div
                                                    key={index}
                                                    onClick={() => primaryView(element.nba)}
                                                    className={element.primaryRecommendation ? `${styles.griditem} ${styles.recommended}` :
                                                        element.isOutlineRequired ? `${styles.griditem} ${styles.otherRcmds}` : styles.griditem}
                                                >{element.nba}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className={styles.layoutBx}>
                                    <h2 className={styles.cxHeading}>Journey Map Score</h2>
                                    <div className={styles.underDesign}>Under Design</div>
                                </div>
                            </React.Fragment> : null
                        }
                    </div>
                    <div className={`${styles.rightContent} ${"col-sm-12 col-md-12 col-lg-4"}`}>
                        <Attributes code={customer.code} divisionCode={props.selectedProductDivision.code} whiteGlove={props.customer.whiteGlove} />
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default Customer;
