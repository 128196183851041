import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./detailedview.module.scss";
import CXScore from "./Tiles/CXScore";
import CAS from "./Tiles/CAS";
import { Spin } from 'antd';
import CXList from './Tiles/CXList';
import * as utils from '../../utils/utils';
import * as consts from '../../utils/constant';
import * as favUtils from '../../utils/favorite.utils'
import Attributes from '../attributes/group';
import * as constants from "../../../src/utils/constant";
import * as nbaurls from "../../../src/utils/nba.utils"

const Group = (props) => {
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- for Breadcrumbs initial loading
    const [groupDetails, setGroupDetails] = useState(null)
    const primaryRecommendation = (props.group && props.group.primaryRecommendation && props.group.primaryRecommendation.length>0) ? Array.isArray(props.group.primaryRecommendation) ? props.group.primaryRecommendation[0].toLocaleLowerCase() : props.group.primaryRecommendation.toLocaleLowerCase() : "";
    const secondaryRecommendations = (props.group && props.group.secondaryRecommendations) ? props.group.secondaryRecommendations : [];
    //EMEA Release- - Added region for BreadCrumbs
    const breadcrumbList = [
        {
            value: `Dashboard (${region})`,
            path: "/dashboard",
        },
        {
            value: `Life Sciences (${region})`,
            path: "/",
        },
    ];
    const getGroupData = (result) => {
        let group = []
        group = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        if (group && group['_source'] && group['_source'].group && group['_source'].group.length > 0) {
             return group['_source'].group.filter((item)=> item.company_region.toLowerCase()===region.toLowerCase())[0]; //EMEA Release- - to find specific region data 
        }
        return group;
    }
    useEffect(() => {
        setLoading(true)
        const input = {
            name: constants.Group_ScoreDetails_Index,
            queryParams: {
                "query": {
                    "match_all": {}
                }
            }
        }
        setLoading(true)
        utils.getDataES('-elasticsearch', 'POST', input)  
        .then(resp => {  
                let groupESData = getGroupData(resp.data);
                const {company_region} = groupESData
                setRegion(company_region)
                setGroupDetails(groupESData)
                setLoading(false)
            }).catch(err => setLoading(false))
    }, [])

    const getSortedCXList = (cxList) => {
        let list = (cxList) ? [...cxList] : []
        list.forEach(element => {
            element.difference = element.score - element.lastQuarterValue
            element.differenceCent = ((element.score - element.lastQuarterValue) / (element.lastQuarterValue)) * 100
        });
        return list.sort(utils.sorting('differenceCent', 'number', false))
    }

    let cxScore, cas, cxList, cxListDP;
    if (groupDetails) {
        cxScore = groupDetails.cxScore;
        if (groupDetails && groupDetails.cxList && groupDetails.cxList.length > 0) {
            cxListDP = groupDetails.cxList.find(item => item.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase());
            cxScore.dataPoints = (cxListDP && cxListDP.dataPoints) ? cxListDP.dataPoints : ''
        }
        cas = groupDetails.cas;
        cxList = getSortedCXList(groupDetails.cxList);
    }

    const createBreadCrumb = () => {
        breadcrumbList[breadcrumbList.length - 1].path = '/dashboard/groupdetail'
        return breadcrumbList;
    }

    const viewScore = (score) => {
        props.setCXScoreData(groupDetails);
        props.setBreadCrumb(createBreadCrumb());
        if (score === 'cas') {
            props.setSASParent('cas');
            props.history.push("/dashboard/score/cas");
        } else {
            props.setSASParent('cx');
            props.history.push("/dashboard/score");
        }
    };

    const getInsights = (nba) => {
        if (groupDetails.nba_insights) {
            let insights = groupDetails.nba_insights.filter(element => element.metrics_name.toLowerCase() === nba.toLowerCase())
            if (insights && insights.length) {
                return insights[0].insights
            }
            return []
        }
        return []
    }

    const primaryView = (nba) => {
        let _nba = ''
        const urls = nbaurls.group_nba_urls(nba, region);
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_INVOICE_AGING.replace(/\s+/g, ''));
                break;
            case consts.REDUCE_RETURN_PERCENTAGE:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.REDUCE_RETURN_PERCENTAGE.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_PRODUCT_AVAILABILITY.replace(/\s+/g, ''));
                break;
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.NEW_IMPROVE_ORDER_VELOCITY.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_OTIF:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.IMPROVE_OTIF)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_OTIF.replace(/\s+/g, ''));
                break;
            case consts.INCREASE_CREDITLINE:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.INCREASE_CREDITLINE)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.INCREASE_CREDITLINE.replace(/\s+/g, ''));
                break;
            default:
                break;
        }

        props.setNBABreadCrumb(createBreadCrumb())
        props.history.push('/dashboard/nba/primaryview')
    }

    useEffect(() => {
        props.setCustomer({})
        props.setSelectedSBS({})
        props.setProductDivision({})
        setFavoriteId(favUtils.chkIsFavorite(props.favorites,region, 1)) //region added for EMEA release
    }, [])

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 1
                favInput.region = region;
                favInput.InputParameters.group = props.group;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    const nbas = [
        {
            nba: consts.REDUCE_RETURN_PERCENTAGE,
            primaryRecommendation: primaryRecommendation === consts.REDUCE_RETURN_PERCENTAGE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.REDUCE_RETURN_PERCENTAGE)
        },
        {
            nba: consts.IMPROVE_PRODUCT_AVAILABILITY,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_PRODUCT_AVAILABILITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_PRODUCT_AVAILABILITY)
        },
        {
            nba: consts.NEW_IMPROVE_ORDER_VELOCITY,
            primaryRecommendation: primaryRecommendation === consts.NEW_IMPROVE_ORDER_VELOCITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.NEW_IMPROVE_ORDER_VELOCITY)
        },
        {
            nba: consts.IMPROVE_OTIF,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_OTIF.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_OTIF)
        },
        {
            nba: consts.IMPROVE_INVOICE_AGING,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_INVOICE_AGING.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_INVOICE_AGING)
        },
        {
            nba: consts.INCREASE_CREDITLINE,
            primaryRecommendation: primaryRecommendation === consts.INCREASE_CREDITLINE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.INCREASE_CREDITLINE)
        }
    ]

    return (
        <Spin spinning={loading}>
            <div className={styles.cxContainer}>
                <React.Fragment>
                    <BreadCrumb
                        list={breadcrumbList}
                        {...props}
                        addToFavorite={addToFavorite}
                        isFavorite={FavoriteId ? true : false} />
                    <div className="row no-gutters">
                        <div className={`${styles.leftContent} ${"col-sm-12 col-md-12 col-lg-8"}`}>
                            {
                                groupDetails ?
                                    <React.Fragment>
                                        <div className={styles.scoreGrid}>
                                            {cxScore && <CXScore cxScore={cxScore} {...props} viewScore={viewScore} />}
                                            {cas && <CAS cas={cas} {...props} viewScore={viewScore} />}
                                            {cxList && <CXList cxList={cxList} {...props} />}
                                        </div>
                                        <div className={styles.layoutBx}>
                                            <h2 className={styles.cxHeading}>Next Best Actions</h2>
                                            <div className={styles.nbaContainer}>
                                                {
                                                    nbas.map((element, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => primaryView(element.nba)}
                                                            className={element.primaryRecommendation ? `${styles.griditem} ${styles.recommended}` :
                                                                element.isOutlineRequired ? `${styles.griditem} ${styles.otherRcmds}` : styles.griditem}
                                                        >{element.nba}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className={styles.layoutBx}>
                                            <h2 className={styles.cxHeading}>Journey Map Score</h2>
                                            <div className={styles.underDesign}>Under Design</div>
                                        </div>
                                    </React.Fragment> : null
                            }
                        </div>
                        <div className={`${styles.rightContent} ${"col-sm-12 col-md-12 col-lg-4"}`}>
                            <Attributes />
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </Spin>
    );
};

export default Group;
