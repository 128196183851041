import * as constants from "./constant";
import jwt_decode from "jwt-decode";

// key  The field, type - Type of that field, sorting - The boolean state value
// sorting = true for ascending
export const sorting = (key, type, sorting) => {
  return function (a, b) {
    if (sorting) {
      if (a[key] === null || (a[key] !== undefined && a[key].length === 0))
        return -1;
      if (b[key] === null || (b[key] !== undefined && b[key].length === 0))
        return 1;
      if (type === "string") {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      } else if (type === "date") {
        if (new Date(a[key]).getTime() < new Date(b[key]).getTime())
          return -1;
        if (new Date(a[key]).getTime() > new Date(b[key]).getTime()) return 1;
        return 0;
      } else if (type === "number") {
        if (a[key] <= b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      }
    } else {
      if (a[key] === null || (a[key] !== undefined && a[key].length === 0))
        return 1;
      if (b[key] === null || (b[key] !== undefined && b[key].length === 0))
        return -1;
      if (type === "string") {
        if (a[key] < b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
      } else if (type === "date") {
        if (new Date(a[key]).getTime() < new Date(b[key]).getTime()) return 1;
        if (new Date(a[key]).getTime() > new Date(b[key]).getTime())
          return -1;
        return 0;
      } else if (type === "number") {
        if (a[key] <= b[key]) return 1;
        if (a[key] > b[key]) return -1;
        return 0;
      }
    }
  };
};

export const isEmpty = (obj) => {
  if (obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
  return true
}

export const getChildViewName = (kpi) => {
  switch (kpi.toUpperCase()) {
    case constants.TOTAL_ADMIN_COMPLAINTS:
      return constants.TOTAL_ADMIN_COMPLAINTS_CHILD_VIEW;
    case constants.HELD_ORDER_INDEX:
      return constants.HELD_ORDER_INDEX_CHILD_VIEW;
    case constants.PERFECT_ORDER_INDEX:
      return constants.PERFECT_ORDER_INDEX_CHILD_VIEW;
    case constants.AVAILABILITY_INDEX:
      return constants.AVAILABILITY_INDEX_CHILD_VIEW;
    case constants.INVOICING_INDEX:
      return constants.INVOICING_INDEX_CHILD_VIEW
    case constants.AR_DISPUTE_INDEX:
      return constants.AR_DISPUTE_INDEX_CHILD_VIEW
    default:
      return ''
  }
}

export const getNBAChildViewName = (nba) => {
  switch (nba.toLowerCase()) {
    case constants.REDUCE_RETURN_PERCENTAGE.toLowerCase():
      return constants.REDUCE_RETURN_PERCENTAGE_CHILD_VIEW;
    case constants.IMPROVE_PRODUCT_AVAILABILITY.toLowerCase():
      return constants.IMPROVE_PRODUCT_AVAILABILITY_CHILD_VIEW;
    case constants.NEW_IMPROVE_ORDER_VELOCITY.toLowerCase():
      return constants.NEW_IMPROVE_ORDER_VELOCITY_CHILD_VIEW;
    case constants.IMPROVE_OTIF.toLowerCase():
      return constants.IMPROVE_OTIF_CHILD_VIEW;
    case constants.IMPROVE_INVOICE_AGING.toLowerCase():
      return constants.IMPROVE_INVOICE_AGING_CHILD_VIEW
    case constants.INCREASE_CREDITLINE.toLowerCase():
      return constants.INCREASE_CREDITLINE_CHILD_VIEW
    case constants.RECURRING_ORDER.toLowerCase():
        return constants.RECURRING_ORDER_CHILD_VIEW
    default:
      return ''
  }
}

export const capitalize = (sentense) => {
  if (sentense) {
    if (sentense.length <= 4) {//sentense.length <= 3 //Fixed to Capitalize if customer length is upto 4char on Tiles
      return sentense.toUpperCase()
    } else {
      let words = sentense.toLowerCase().split(' ').map(word => (
        word = word.charAt(0).toUpperCase() + word.slice(1, word.length)
      ));
      return words.join(' ')
    }
  }
  return ''
}

export const textLimitTo = (text, num) => {
  if (text.length > num) {
    return text.slice(0, num) + '...'
  }
  return text
}

export const getUserEmail = () => {
  const _token = JSON.parse(localStorage.getItem("__tf_token"));
  const decoded = jwt_decode(_token.id_token);
  return decoded.email.toLowerCase()
}

const getToken = () => {
  const token = JSON.parse(localStorage.__tf_token)
  return token.id_token
}

export const headers = () => {
  return {
    'Content-Type': 'application/json',
    'AuthToken': getToken()
  }
}

export const getData = (url, method, input) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}${url}`, {
      method: method,
      headers: headers(),
      body: input ? JSON.stringify(input) : undefined
    })
      .then(response => response.json())
      .then(response => resolve(response))
      .catch((error) => {
        console.log(error)
        if (error.message === 'Failed to fetch') {
          // window.open(process.env.REACT_APP_URL, '_self')
        }
      })
  })
}

export const getDataES = (url, method, input) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_ELASTICSEARCH_URL}${url}`, {
      method: method,
      headers: headers(),
      body: input ? JSON.stringify(input) : undefined
    })
      .then(response => response.json())
      .then(response => resolve(response))
      .catch((error) => {
        console.log(error)
        if (error.message === 'Failed to fetch') {
          window.open(process.env.REACT_APP_URL, '_self')
        }
      })
  })
}


export const isAdminUser = () => {
  const _token = JSON.parse(localStorage.getItem("__tf_token"));
  const decoded = jwt_decode(_token.id_token);
  const groups = decoded['cognito:groups']
  if (groups && groups.length) {
    if (groups.includes(process.env.REACT_APP_ADMIN_ROLE)) {
      return true
    }
  }
  return false
}

export const getRrecommendation = (recommendation) => {
  switch (recommendation) {
    case '-':
      return 'No Transactions Available for NBA'
    case 'No Action Needed':
      return 'No Action Needed'
    case null:
      return 'No Recommended NBA'
    default:
      return recommendation
  }
}

export const getRecommendationFlag = (nbas,nba) => {
  let flag = false;
  nbas.forEach(ele => {
      if (ele.toLocaleLowerCase() === nba.toLowerCase()) {
          flag = true;
      }
  });
  return flag;
}

export const getRecommendationColorCode = (recommendation) => {
  switch (recommendation) {
    case '-':
      return 'bg-grey'
    case 'No Action Needed':
      return 'bg-green'
    case null:
      return 'bg-grey'
    default:
      return 'bg-red'
  }
}

export const convertToInternationalCurrencySystem = (labelValue) => {
  return Math.abs(Number(labelValue)) >= 1.0e+9
    ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs(Number(labelValue)) >= 1.0e+6

      ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
      // Three Zeroes for Thousands
      : Math.abs(Number(labelValue)) >= 1.0e+3

        ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"

        : Math.abs(Number(labelValue));

}

export const groupQueryParams = () => {
  let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
  return {
    name: constants.Group_Dashboard_Index,
    queryParams: {//Added for EMEA release
      "_source": {
        "excludes": ["*.customers"]
      },
      "query": {
        "nested": {
          "path": "group.customers",
          "query": {
            "bool": {
              "must": [
                {
                  "match": {
                   "group.customers.company_region": region
                  }
                },
                {
                  "match": {
                    "group.customers.whiteGlove": "true"
                  }
                }
              ]
            }
          },
          "inner_hits": {
            "_source": false,
            "size": 100, //modified for EMEA release
            "docvalue_fields": [
              "group.customers.company_region.keyword",//added for EMEA release
              "group.customers.code.keyword",
              "group.customers.name.keyword",
              "group.customers.id",
              "group.customers.cxScore",
              "group.customers.lastQuarterCxScore",
              "group.customers.lastQuarterCas",
              "group.customers.responseCount",
              "group.customers.cas",
              "group.customers.volatility.keyword",
              "group.customers.primaryRecommendation.keyword",
              "group.customers.secondaryRecommendations.keyword",
              "group.customers.whiteGlove.keyword"
            ]
          }
        }
      }
    }
  }
}

export const groupDetailQuery = () => {
  return {
    name: constants.Group_ScoreDetails_Index,
    queryParams: {
      "query": {
        "match_all": {}
      }
    }
  }
}

export const pdDetailQuery = () => {
  return {
    name: constants.PD_ScoreDetails_Index,
    queryParams: {
      "query": {
        "match_all": {}
      }
    }
  }
}

export const groupCustomerQuery = (customerArray) => {
  let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
  let customerFilter = customerArray.map(item => {
    let matchObj = {
      "match": {
        "group.customers.code": item.InputParameters.customer.code
      }
    }
    return matchObj
  });
  
  return {
    name: constants.Group_Dashboard_Index,
    queryParams: {
      "_source": {
        "excludes": [
          "*.customers"
        ]
      },
      "query": {
        "nested": {
          "path": "group.customers",
          "query": {
                      "bool": {
                          "should": customerFilter //must
                      }
            },
          "inner_hits": {
            "_source": false,
            "size": 100,
            "docvalue_fields": [
              "group.customers.company_region.keyword",//added for EMEA release
              "group.customers.code.keyword",
              "group.customers.name.keyword",
              "group.customers.id",
              "group.customers.cxScore",
              "group.customers.lastQuarterCxScore",
              "group.customers.lastQuarterCas",
              "group.customers.responseCount",
              "group.customers.cas",
              "group.customers.volatility.keyword",
              "group.customers.primaryRecommendation.keyword",
              "group.customers.secondaryRecommendations.keyword",
              "group.customers.whiteGlove.keyword"
            ]
          }
        }
      }
    } 
	}
  
}

export const custDetailQuery = (customerArray) => {
  let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
   let customerFilter = customerArray.map(item => {
    let matchObj = {
      "match": {
        "code": item.InputParameters.customer.code
      }
    }
    return matchObj
  });
  let groupCustConstant;
  if(region.toLowerCase()==="emea")
  groupCustConstant = constants.Customer_EMEA_ScoreDetails_Index
  else
  groupCustConstant = constants.Customer_ScoreDetails_Index
  return {
    name: groupCustConstant,
    queryParams: {
      "size": 10,
      "query": {
        "bool": {
          "should": customerFilter
          //"must": customerFilter
        }
      }
    }
  }
}

export const pdQuery = (itemArray) => {
  let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
  let itemFilter = itemArray.map(item => {
    let bidCustObj = {}
    bidCustObj["custcode"] = item.InputParameters.customer.code
    bidCustObj["PD"] = item.InputParameters.productDivision.code
    return bidCustObj
  });
  const uniquePD = [...new Set(itemFilter.map(item => item.PD))]
  let matchArray = []
  for(let i=0;i<uniquePD.length;i++){
    let getCustIDObj = itemFilter.filter(itm=>itm.PD === uniquePD[i])
    if(getCustIDObj && getCustIDObj.length>0){
      let custIDObj = []
      for(let j=0;j<getCustIDObj.length;j++){
        let cust1 = {
          "match": {
            "productDivisions.customers.code": getCustIDObj[j].custcode
          }
        }
        custIDObj.push(cust1)
      }
      /*
      let custPD = {
        "bool": {
          "must": [
            {
              "bool": {
                "should": custIDObj
              }
            },
            {
              "match": {
                "productDivisions.customers.productdivision": uniquePD[i]
              }
            }
          ]
        }
      }*/
      let custPD = {
        "bool": {
          "must": [
            {
              "bool": {
                "should": custIDObj
              }
            },
            {
              "match": {
                "productDivisions.customers.productdivision": uniquePD[i]
              }
            }
            /*,
            {
              "match": {
                "productDivisions.customers.company_region": region
              }
            }*/
          ]
        }
      }
      matchArray.push(custPD)
    }   

  }
  return {
    name: constants.ProductDivision_Dashboard_Index,
    queryParams: {
      "_source": {
        "excludes": "*.customers"
      },
      "query": {
        "nested": {
          "path": "productDivisions.customers",
          "query": {
            "bool": {
              "should": matchArray
            }
          },
          "inner_hits": {
            "_source": false,
            "size": 50,
            "docvalue_fields": [
              "productDivisions.customers.company_region.keyword",//added for EMEA release
              "productDivisions.customers.code.keyword",
              "productDivisions.customers.name.keyword",
              "productDivisions.customers.whiteGlove.keyword",
              "productDivisions.customers.cxScore",
              "productDivisions.customers.lastQuarterCxScore",
              "productDivisions.customers.lastQuarterCas",
              "productDivisions.customers.responseCount",
              "productDivisions.customers.cas",
              "productDivisions.customers.id",
              "productDivisions.customers.primaryRecommendation.keyword",
              "productDivisions.customers.secondaryRecommendations.keyword",
              "productDivisions.customers.productdivision.keyword",
              "productDivisions.customers.volatility.keyword"
            ]
          }
        }
      }
    }

  }
}

export const pdQuerySBS = () => {
  let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
  return {
    name: constants.ProductDivision_Dashboard_Index,
    queryParams:{
      "_source": {
        "excludes": "*.customers"
      },
      "query": {
        "nested": {
          "path": "productDivisions.customers",
          "query": {
            "bool": {
              "must": [
                {
                  "match": {
                    "productDivisions.customers.productdivision": "BID"
                  }
                }
              ]
            }
          },
               "inner_hits": {
            "_source": false,
            "docvalue_fields": [
              "productDivisions.customers.company_region.keyword",
              "productDivisions.customers.code.keyword",
              "productDivisions.customers.name.keyword",
              "productDivisions.customers.whiteGlove.keyword",
              "productDivisions.customers.cxScore",
              "productDivisions.customers.lastQuarterCxScore",
              "productDivisions.customers.lastQuarterCas",
              "productDivisions.customers.responseCount",
              "productDivisions.customers.cas",
              "productDivisions.customers.productdivision.keyword",
              "productDivisions.customers.id",
              "productDivisions.customers.volatility.keyword",
              "productDivisions.customers.primaryRecommendation.keyword",
              "productDivisions.customers.secondaryRecommendations.keyword"
            ]
          }
        }
      }
    }
  }
}
//Prod Div KPI /NBA pages
export const pdCsutomerQuery = (pdCustomerArr) =>{
  let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
  let itemFilter = pdCustomerArr.map(item => {
    let bidCustObj = {}
    bidCustObj["custcode"] = item.InputParameters.customer.code
    bidCustObj["volatility"] = item.InputParameters.customer.volatility
    bidCustObj["custDetailId"] = item.InputParameters.cxScoreDetails.id
    bidCustObj["PD"] = item.InputParameters.productDivision.code
    return bidCustObj
  });
  let matchArray = []
  for(let i=0;i<itemFilter.length;i++){
    let custIDObj = []
        let cust1 = {
          "match_phrase": {
            "code": itemFilter[i].custcode
          }
        }
        custIDObj.push(cust1)
        let cust3 = {
          "match": {
            "volatility": itemFilter[i].volatility
          }
        }
        custIDObj.push(cust3)
        let custPD = {
          "bool": {
            "must": [cust1,
          
              {
                "match": {
                  "product_division_code": itemFilter[i].PD
                }
              },//added company_region filter
              {
                "match": {
                  "company_region": region
                }
              }
            ]
          }
        }
        matchArray.push(custPD)
    }
    let pdCustConstant;
    if(region.toLowerCase()==="emea")
    pdCustConstant = constants.PD_EMEA_customer_ScoreDetails_Index
    else
    pdCustConstant = constants.PD_customer_ScoreDeatils_Index
  return {
    name: pdCustConstant,
    queryParams: {
      "size": pdCustomerArr.length,
      "query": {
        "bool": {
          "should": matchArray
        }
      }
    }
  }
}

export const pdSBSDetailsQuery = (pdArray) =>{
  let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
  let PDFilter = pdArray.map(item => {
    let matchObj = {
      "match": {
        "productDivisions.code": item.InputParameters.productDivision.code
      }
    }
    return matchObj
  });
  
  return {
    name: constants.SBS_ScoreDetails_Index,
    queryParams: {
      "_source": "false",
      "query": {
        "nested": {
          "path": "productDivisions",
          "query": {
            "bool": {
              "must": PDFilter
            }
          },
          "inner_hits": {

          }
        }
      }
    }
  }
}

//subrgDetailQuery
export const subrgDetailQuery = (subRegionItemsArray)=>{
  return {
    name: constants.SubRegion_Dashboard_Index,
    queryParams:{}
  }
}

//subrgNonDetailQuery
export const subrgNonDetailQuery = (subRegionItemsArray)=>{
  return {
    name: constants.SubRegion_Score_Index,
    queryParams: {
        "query": {
            "match_all": {}
        }
    }
  }
}


export const resetPBIView = () => {
  document.getElementById('powerbi').src += '';
}