import React from "react";
import styles from "./favorite.module.scss";
import prodStyles from "./product.module.scss";
import * as utils from "../../utils/utils";

function Favorite(props) {

  const {
    title,
    type,
    subTitle,
    view,
    ViewType,
    detailPageId,
    region
  } = props.favorite;

  let name,
    cxScore,
    lastQuarterCxScore,
    cas,
    lastQuarterCas,
    volatility,
    primaryRecommendation,
    cxBenchmark,
    casBenchmark,
    responseCount,
    company_region;//added region field-- EMEA Release

  if (detailPageId === 1) {//group level
    name = props.favorite.InputParameters.group.name
    cxScore = props.favorite.InputParameters.group.cxScore
    lastQuarterCxScore = props.favorite.InputParameters.group.lastQuarterCxScore
    cas = props.favorite.InputParameters.group.cas
    lastQuarterCas = props.favorite.InputParameters.group.lastQuarterCas
    volatility = props.favorite.InputParameters.group.volatility
    primaryRecommendation = props.favorite.InputParameters.group.primaryRecommendation
    cxBenchmark = props.favorite.InputParameters.group.cxBenchmark
    casBenchmark = props.favorite.InputParameters.group.casBenchmark
    responseCount = props.favorite.InputParameters.group.responseCount
    company_region= props.favorite.InputParameters.group.company_region//need to add here -- EMEA Release-
    //added for EMEA release
    name = `<div class="favoriteFlex"><div>${name} (${company_region})</div></div>`
  } else if (detailPageId === 2) {//Customer level
    name = utils.textLimitTo(utils.capitalize(props.favorite.InputParameters.customer.name), 20)
    cxScore = props.favorite.InputParameters.customer.cxScore
    lastQuarterCxScore = props.favorite.InputParameters.customer.lastQuarterCxScore
    cas = props.favorite.InputParameters.customer.cas
    lastQuarterCas = props.favorite.InputParameters.customer.lastQuarterCas
    volatility = props.favorite.InputParameters.customer.volatility
    primaryRecommendation = props.favorite.InputParameters.customer.primaryRecommendation
    cxBenchmark = props.favorite.InputParameters.customer.cxBenchmark
    casBenchmark = props.favorite.InputParameters.customer.casBenchmark
    responseCount = props.favorite.InputParameters.customer.responseCount
    company_region= props.favorite.InputParameters.customer.company_region

    const { productDivision } = props.favorite.InputParameters;
    //Added for EMEA release 
    if (utils.isEmpty(productDivision)) {
      name = `<div class="favoriteFlex"><div>${utils.textLimitTo(name, 15)} (${company_region})</div></div>`
    }
    //Modified for EMEA release 
    if (!utils.isEmpty(productDivision)) {
      name = `<div class="favoriteFlex"><div>${productDivision.code} (${company_region})</div><div class="forwardTitleArrow"></div><div>${utils.textLimitTo(name, 15)}(${company_region})</div></div>`
    }

  } if (detailPageId === 3) {//Product Division level
    name = props.favorite.InputParameters.productDivision.code
    cxScore = props.favorite.InputParameters.productDivision.cxScore
    lastQuarterCxScore = props.favorite.InputParameters.productDivision.lastQuarterCxScore
    cas = props.favorite.InputParameters.productDivision.cas
    lastQuarterCas = props.favorite.InputParameters.productDivision.lastQuarterCas
    volatility = props.favorite.InputParameters.productDivision.volatility
    primaryRecommendation = props.favorite.InputParameters.productDivision.primaryRecommendation
    cxBenchmark = props.favorite.InputParameters.productDivision.cxBenchmark
    casBenchmark = props.favorite.InputParameters.productDivision.casBenchmark
    responseCount = props.favorite.InputParameters.productDivision.responseCount
    company_region = props.favorite.InputParameters.productDivision.company_region //added here -- EMEA Release
    name = `<div class="favoriteFlex"><div>${name} (${company_region})</div></div>`
  } else if (detailPageId === 4) {//BU level
    const { productDivision } = props.favorite.InputParameters
    name = props.favorite.InputParameters.sbs.code
    cxScore = props.favorite.InputParameters.sbs.cxScore
    lastQuarterCxScore = props.favorite.InputParameters.sbs.lastQuarterCxScore
    cas = props.favorite.InputParameters.sbs.cas
    lastQuarterCas = props.favorite.InputParameters.sbs.lastQuarterCas
    volatility = props.favorite.InputParameters.sbs.volatility
    primaryRecommendation = props.favorite.InputParameters.sbs.primaryRecommendation
    cxBenchmark = props.favorite.InputParameters.sbs.cxBenchmark
    casBenchmark = props.favorite.InputParameters.sbs.casBenchmark
    responseCount = props.favorite.InputParameters.sbs.responseCount
    company_region = props.favorite.InputParameters.sbs.company_region //added here -- EMEA Release

     //Added for EMEA release
    primaryRecommendation = primaryRecommendation[0]? primaryRecommendation[0]==="-"? null : primaryRecommendation[0]  : null;
   
    //name = `<div class="favoriteFlex"><div>${productDivision.code}</div><div class="forwardTitleArrow"></div><div>${name}</div></div>`
    name = `<div class="favoriteFlex"><div>${productDivision.code} (${company_region})</div><div class="forwardTitleArrow"></div><div>${name} (${company_region})</div></div>`
  }
  if (detailPageId === 5) {//Subregion level
    name = props.favorite.InputParameters.subregion.name
    cxScore = props.favorite.InputParameters.subregion.cxScore
    lastQuarterCxScore = props.favorite.InputParameters.subregion.lastQuarterCxScore
    cas = props.favorite.InputParameters.subregion.cas
    lastQuarterCas = props.favorite.InputParameters.subregion.lastQuarterCas
    volatility = props.favorite.InputParameters.subregion.volatility
    primaryRecommendation = props.favorite.InputParameters.subregion.primaryRecommendation
    cxBenchmark = props.favorite.InputParameters.subregion.cxBenchmark
    casBenchmark =props.favorite.InputParameters.subregion.casBenchmark
    responseCount = props.favorite.InputParameters.subregion.responseCount
    company_region= props.favorite.InputParameters.subregion.company_region//added here -- EMEA Release
    //added for EMEA release
    name = `<div class="favoriteFlex"><div>${name} (${company_region})</div></div>`
  }

  let score = 0
  if (type.toLowerCase() === 'kpi') {
    const _score = props.favorite.InputParameters.kpi.score
    score = _score ? _score : '-'
  } else if (ViewType === 2 || ViewType === 9) {

    if (props.favorite.InputParameters.sasParent.toLowerCase() === 'cx') {
      const _score = props.favorite.InputParameters?.cxScoreDetails?.cxScore?.score
      score = _score ? _score : '-'
    }
    else {
      if (props.favorite.InputParameters.cxScoreDetails.cas.responseCount)
        score = props.favorite.InputParameters.cxScoreDetails.cas.score
      else
        score = '-'
    }
  }

  const clickOnTooltip = (e) => {
    e.stopPropagation()
  }

  const getRrecommendation = (primaryRecommendation) => {
    return utils.getRrecommendation(primaryRecommendation)
  }

  const getRecommendationColorCode = (primaryRecommendation) => {
    return utils.getRecommendationColorCode(primaryRecommendation)
  }

  return (
    <React.Fragment>
      {
        ViewType !== 1 ? <div
          onClick={() => props.showDetail(props.favorite)}
          className={styles.cardContainer}
        >
          <div className={styles.cardHeader}>
            <div className={styles.cardTitle} dangerouslySetInnerHTML={{ __html: `${title} (${region})` }}></div>
            <div id="favorite"
              onClick={(e) => props.removeFavorite(e, props.favorite)}
              className={`${styles.favoritePinRed} ${styles.tooltip}`}><span onClick={(e) => clickOnTooltip(e)} className={styles.tooltiptext}>Unpin from Favorites</span></div>
          </div>
          {
            view !== null ? <div className={styles.cardSubHeader0}>
              <div className={styles.cardSubTitle0}>{view}</div>
            </div> : null
          }
          {
            (type.toLowerCase() === 'kpi' || type.toLowerCase() === 'nba' || ViewType === 2 || ViewType === 9) ?
              <div className={`${styles.cardSubHeader} ${(type.toLowerCase() === 'kpi' || ViewType === 2 || ViewType === 9) ? `${styles.kpitile}` : ''}`}>
                <div className={(type.toLowerCase() === 'kpi' || type.toLowerCase() === 'nba') ? styles.cardSubTitle : styles.cardSubTitlehidden} dangerouslySetInnerHTML={{ __html: subTitle }} ></div>
                <div className={(type) ? `${styles.subTitle}` : ''}>{type}</div>
              </div>
              : null
          }
          {
            (type.toLowerCase() === 'kpi' || type.toLowerCase() === 'nba' || ViewType === 2 || ViewType === 9) ?
              <div className={styles.cardBody}>
                <div className={styles.innerLayout1}>
                  <div className={styles.bodyTitle}>{type.toLowerCase() === 'nba' ? <span className={styles.nbaHidden}>Score</span> : 'Score'}</div>
                  <span className={styles.bodyValue}>
                    {
                      type.toLowerCase() === 'nba' ? <span className={styles.nbaHidden}>0</span> : (typeof score === 'number' ? Number(score).toFixed(0) : <div className={prodStyles.dash}>-</div>)
                    }
                  </span>
                </div>
              </div> : null
          }
        </div>
          :
          <div
            onClick={() => props.showDetail(props.favorite)}
            className={`${prodStyles.cardContainer} ${(type === "productdivision" || type === "businessunits") ? `${prodStyles.cardMargin}` : ""
              } ${type === "product" ? `${prodStyles.cardAfter}` : ""}`}
          >
            <div className={prodStyles.cardHeader}>
              <div className={prodStyles.cardTitle} dangerouslySetInnerHTML={{ __html: name }}></div>
              <div id="favorite"
                onClick={(e) => props.removeFavorite(e, props.favorite)}
                className={`${styles.favoritePinRed} ${styles.tooltip}`}><span onClick={(e) => clickOnTooltip(e)} className={styles.tooltiptext}>Unpin from Favorites</span></div>
            </div>
            <div className={prodStyles.cardBody}>
              <div className={prodStyles.innerLayout}>
                <div className={prodStyles.bodyTitle}>CX Score</div>
                {
                  cxScore ?
                    <span
                      className={`${prodStyles.bodyValue} ${cxScore >= cxBenchmark ? "text-color-green" : "text-color-red"
                        }`}
                    >
                      {Number(cxScore).toFixed(0)}
                      <span
                        className={
                          (cxScore === lastQuarterCxScore) ? `${prodStyles.arrowNoChange}` : (cxScore > lastQuarterCxScore) ? `${prodStyles.arrowUp}` : `${prodStyles.arrowDown}`
                        }
                      ></span>
                    </span> : <div className={prodStyles.dash}>-</div>
                }
              </div>
              <div className={prodStyles.innerLayout}>
                <div className={prodStyles.bodyTitle}>CAS</div>
                {
                  responseCount ?
                    <span
                      className={`${prodStyles.bodyValue} ${cas >= casBenchmark ? "text-color-green" : "text-color-red"
                        }`}
                    >
                      {Number(cas).toFixed(0)}
                      <span
                        className={
                          (cas === lastQuarterCas) ? `${prodStyles.arrowNoChange}` : (cas > lastQuarterCas) ? `${prodStyles.arrowUp}` : `${prodStyles.arrowDown}`
                        }
                      ></span>
                    </span> : <div className={prodStyles.dash}>-</div>
                }
              </div>
              <div className={prodStyles.innerLayout}>
                <div className={prodStyles.bodyTitle}>Volatility</div>
                {
                  cxScore ? <span className={prodStyles.bodyValue}>{volatility}</span> :
                    <div className={prodStyles.dash}>-</div>
                }
              </div>
            </div>
            <div
              className={`${prodStyles.cardFooter} ${getRecommendationColorCode(primaryRecommendation)}`}
            >
              <b>{getRrecommendation(primaryRecommendation)}</b>
            </div>
          </div>
      }
    </React.Fragment>
  );
}

export default Favorite;