import React, { useState  } from 'react'
import "./navbar.scss";

const Navbar = (props) => {
  const goHome = () => {
    props.history.push('/dashboard')
  }
  const openQuickLink = (url) => {
    window.open(url, '_blank')
  }
  const goToAmbiguity = () =>{
    props.history.push('/dashboard/ambiguity')
  }
  return (
    <nav className="navBg">
      <ul>
      <li>
          <a className="active">
            <i onClick={goHome} className="dashboard-icn" title="Homepage"></i>
            <span>Dashboard</span>
          </a>
        </li>
        <li>
          <a>
            <i onClick={()=>openQuickLink('https://forms.office.com/Pages/ResponsePage.aspx?id=LXJ9toqqd0ehaevremo7Zwp_KoqUJAVOlWEWUsl3RzlURDJaVkRIVlhQRTdTRFkxSVJTQ05OVDlMViQlQCN0PWcu')} title="Report an issue or submit feedback" className="feedback-icn"></i>
            <span>Submit Feedback</span>
          </a>
        </li>
        <li>
          <a>
            <i onClick={()=>openQuickLink('https://thermofisher.sharepoint.com/sites/VOICEPlatform')} title="VOICE Info & FAQs" className="info-icn"></i>
            <span>VOICE Info and FAQs</span>
          </a>
        </li>
        {/* <li>
          <a>
            <i onClick={goToAmbiguity} title="Ambiguity" className="ambi-icn"></i>
            <span>Ambiguity</span>
          </a>
        </li> */}
        {/* <li>
          <a>
            <i className="view-icn"></i>
            <span>CCO View</span>
          </a>
        </li>
        <li>
          <a>
            <i className="summary-icn"></i>
            <span>CX Summary</span>
          </a>
        </li>
        <li>
          <a>
            <i className="revenue-icn"></i>
            <span>Revenue/P&amp;L</span>
          </a>
        </li>
        <li>
          <a>
            <i className="account-icn"></i>
            <span>Account Hierarchies</span>
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
